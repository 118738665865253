<template>
    <div class="error404">
        <div class="image"></div>
        <img class="symbol-background" src="@/assets/images/generaxion-white.svg" alt=""/>

        <div class="content">
            <h1>404</h1>

            <h2>Siden blev ikke fundet</h2>

            <router-link :to="to" class="btn btn-default btn-fill">
                Klik her for at gå til forsiden
            </router-link>
        </div>
    </div>
</template>

<script>
  import StorageService from '@/services/_app/storage/StorageService'

  export default {
    name: 'Error404',
    computed: {
      to () {
        const route = { name: 'login' }
        const state = StorageService.get('authState')
        if (state) {
          route.name = state === 'client' ? 'frontend' : 'backend'
        }

        return route
      }
    }
  }
</script>

<style lang="scss" >
    @import "~@/assets/scss/variables/_colors.scss";
    .error404 {
        color: white;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $sgme-primary;
        background: linear-gradient(to bottom, $sgme-primary 0%, $sgme-tertiary 100%);
        background-size: 150% 150%;

        .symbol-background {
            position: absolute;
            bottom: -150px;
            right: -100px;
            width: 700px;
            opacity: 0.25;
            z-index: 0;
        }

        .image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url('~@/assets/images/error/404.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 0%;
            z-index: 1;
            opacity: 0.3;
        }

        .content {
            position: relative;
            text-align: center;
            z-index: 2;

            h1 {
                font-size: 60pt;
                font-weight: 500;
                letter-spacing: 10px;
            }

            h2 {
                font-size: 20pt;
                font-weight: 200;
            }
        }

        .btn {
            color: #fff;
            border: 1px solid #fff;
            margin-top: 20px;
            opacity: 1;
            background-color: transparent;

            &:hover {
                background-color: $sgme-primary-dark;
                border-color: $sgme-primary-dark
            }
        }
    }
</style>
